
import CalendlyInline from "./sub-components/buttons/CalendlyInline";
import { Container } from "react-bootstrap";
// import { MyMap } from './sub-components/Map';


const Contact = () => {

    return <>
		<Container className='justify-items-center'>
			<section>
				<CalendlyInline />
			</section>
			{/* <section id='map' className='mt-5 mb-5'>
				<MyMap />
			</section> */}
		</Container>
	</>;
}

export default Contact;


import React from 'react';
import { NavLink } from 'react-router-dom';
import { useThemeContext } from "../../hooks/usethemeContext";
import { Icon } from '@iconify/react';
import logo from "../../assets/images/logo.png";
import { Col, Container, Row, Nav } from 'react-bootstrap';
import { switchTheme } from '../../assets/styles';
import { BrowserView, MobileView, isBrowser, isMobileOnly } from 'react-device-detect';

const Footer = () => {
    const { darkMode } = useThemeContext();
    const { iconStyle, borderTop } = switchTheme(darkMode);
    const currentYear = new Date().getFullYear();
    const lg = 6, md = 12;

    function handleClick(e) {
        if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            console.log("Preventing default behavior.");
            e.preventDefault();
        }
    }
    
    return <>
        <div className={`footer-area pt-3 pb-1`} style={borderTop}>
            <div>
                <Container>
                    <Row className="align-items-center">
                        <Col md={md} lg={lg}>
                            <div>
                                <NavLink to="/">
                                    <img src={logo} width={"17%"} alt="logo" />
                                </NavLink>
                                <p>
                                    Let Us <strong><span>Help You Grow</span></strong> 
                                    &nbsp;Your <strong><span></span></strong> 
                                    Business <strong><span>By Teaching You</span></strong> How To 
                                    <strong><span>&nbsp;Automate </span></strong>  Your
                                    <strong><span>&nbsp;Marketing, Customer Capture, And Sales</span></strong> 
                                </p>
                            </div>
                        </Col>

                        <Col md={md} lg={lg}>
                           <div>
                                <span className='mr-5'>
                                    <a className='' href={process.env.REACT_APP_FB} target="_blank" rel='noreferrer'>
                                        <Icon icon="iconoir:facebook-squared" style={ iconStyle }/>
                                    </a>
                                </span>
                                {/* <span className='mx-3'>
                                    <a href="https://www.instagram.com/" target="_blank" rel='noreferrer'>
                                    <Icon icon="ph:instagram-logo" style={ iconStyle }/>
                                    </a>
                                </span> */}
                                {/* <span className='mr-5'>
                                    <Nav.Link className='' href='tel:2393995439' target="_blank" rel='noreferrer' onClick={handleClick} >
                                        <Icon icon="fluent:call-24-filled" style={ iconStyle }/> (239) 399-5439
                                    </Nav.Link>
                                </span> */}
                                {
                                    isMobileOnly ?
                                    <Nav.Link className='text-light' href='tel:2393995439' target="_blank" rel='noreferrer'>
                                        <Icon icon="fluent:call-24-filled" style={ iconStyle } />
                                        (239) 399-5439
                                    </Nav.Link>
                                    :
                                    <Nav.Link className='text-light' href='#' target="_blank" rel='noreferrer'>
                                        <Icon icon="fluent:call-24-filled" style={ iconStyle } />
                                        (239) 399-5439
                                    </Nav.Link>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <div className="footer-copy-right">
                <Container>
                    <p>
                        Copyright &copy;{currentYear} Metaverse Group. Designed by&nbsp;
                        <a href="https://metaversegroup.tech/contact" target="_blank" rel='noreferrer'>
                            Analitico
                        </a>
                    </p>
                </Container>
            </div>
        </div>
    </>;
}

export default Footer;


import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import about1 from '../../assets/images/about-1.webp';
// import about2 from '../../assets/images/about-2.webp';


const AboutUs = () => {
	// define style by darkMode
	const { darkMode } = useThemeContext();
	const { mainBannerTextColor } = switchTheme(darkMode);

	// const style = {color: '#0e65d7'};
	// const sm = '12', lg = '6'; // setting for 2 people about page
	const styles = { margin:  "auto", width:'25%'};
	const space = 3;

    return (
      <>
        <div className={`about-area mt-5 pb-${space}`}>
			<div className={`text-center pb-${space} border-bottom-0`}>
				<h1 className={mainBannerTextColor}>
					Who We Are!
				</h1>
			</div>
			<Container className="justify-items-center justify">
				<Row className="justify-items-center">
					<Col> {/* setting for 1 person about page */}
					{/* <Col sm={sm} lg={lg}> */} {/* setting for 2 people about page */}
						<Row>
							<div className={`pt-${space}`}>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about1}
									alt="First slide"
								/>
								<h4 className={`text-center ${mainBannerTextColor}`}>
									HI! I'm LaKesha
								</h4>
								<p>
									HI! &#128075;&#127998; my name is LaKesha Shaw, but I go by Kesha.  I'm married to my husband, Earl, and we love to travel internationally and we love going to beautiful Caribbean beaches. I am a 3-time breast cancer survivor who has never given up and never stopped helping people along the way. Although I've had challenges in my life, I've overcome them with the help of my family and close friends.  Family is extremely important in my life and it's my driving force.
								</p>
								<p>
									I started in financial auditing for the federal government over 25 years ago working on compliance and efficiency audits. I would do my family and my friends' taxes during the tax season while working full time. I started doing independent tax, accounting, and consulting services in 2001 when one of my mentors needed help at her tax business. I began to realize that I truly enjoyed doing taxes more than working as an auditor.
								</p>
								<p>
									I'm a proud graduate of Virginia Union University, with a BS degree in Accounting.  In addition, I'm a licensed Certified Public Accountant (CPA) in Maryland and Florida as well as a member of the National Association of Tax Preparers.  I also love to do volunteer work in the community as a member of Alpha Kappa Alpha Sorority Incorporated.
								</p>
							</div>
						</Row>
					</Col>
					{/* <Col sm={sm} lg={lg}>
						<Row>
									<div className={`pt-${space}`}>
									<h4 className={`text-center ${mainBannerTextColor}`}>
										Jose
									</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about2}
									alt="First slide"
								/>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
							</div>
						</Row>
					</Col> */}
						<Container>
							{/* <h5 className={`text-justify ${mainBannerTextColor}`}>
								Our Commitment to You!
							</h5> */}
							{/* <p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p> */}
							<h5 className={`text-center ${mainBannerTextColor}`}>
								My Commitment to You!
							</h5>
							<p >
								We manage over 1200 clients nationwide and I truly enjoy helping those families and business with their taxes. My goal is to handle your diverse tax needs while giving each of you the personal attention you deserve.  The Taxpayer Bill of Rights says, “Taxpayers have the right to pay no more than the correct amount of tax.”   So, my intention is to look out for your bottom line by legally minimizing your tax liability so that you can keep the most money in your pocket.  I'm also dedicated to helping other tax preparers grow their business by providing training, mentorship, and a business  opportunities.
							</p>
					</Container>
					<NavLink
						to="/contact"
						className={`mt-${space} btn btn-outline-warning`}
						style={{maxWidth: '300px', margin: 'auto'}}
					>
						Contact us
					</NavLink>
				</Row>
          	</Container>
        </div>
      </>
    );
};

export default AboutUs;

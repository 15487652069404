
import { useLocation } from 'react-router-dom';
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Icon } from "@iconify/react";
import { BrowserView, MobileView, isBrowser, isMobileOnly } from 'react-device-detect';
// import ThemeSwitch from '../sub-components/buttons/ThemeSwitch';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../assets/images/logo.png';

export default function NavBar() {

  // define button style by darkMode
  const { darkMode } = useThemeContext();
  const { variant, bg, iconStyle } = switchTheme(darkMode);
  const location = useLocation(); // Get current location


  return <>
    <div className='pb-3 mt-5'>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg={bg} variant={variant}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={Logo}
              width={"45%"}
              height={"45%"}
              className="d-inline-block align-top"
              alt="Company Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav 
              variant="underline" 
              activeKey={location.pathname} 
              className="me-auto"
            >
              <Nav.Item>
                <Nav.Link href="/">Welcome</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/business-opportunities"} href="/business-opportunities">Business Opportunities</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/about"} href="/about">About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/contact"} href="/contact">Contact Us</Nav.Link>
              </Nav.Item>
              <Nav.Link href="https://buy.stripe.com/cN203rdOG54P8Fi8ww" target="_blank" rel='noreferrer'>
                Make Payments
              </Nav.Link>
            </Nav>
            <Nav className="me-auto">
              {
                isMobileOnly ?
                  <Nav.Link className='text-light' href='tel:2393995439' target="_blank" rel='noreferrer'>
                    <Icon icon="fluent:call-24-filled" style={ iconStyle } />
                    (239) 399-5439
                  </Nav.Link>
                  :
                  <Nav.Link className='text-light' href='#' target="_blank" rel='noreferrer'>
                    <Icon icon="fluent:call-24-filled" style={ iconStyle } />
                    (239) 399-5439
                  </Nav.Link>
              }
              

              <Nav.Link href={process.env.REACT_APP_FB} target="_blank" rel='noreferrer'>
                <Icon icon="iconoir:facebook-squared" style={ iconStyle }/>
              </Nav.Link>
              {/* <Nav.Link href="https://www.instagram.com/" target="_blank" rel='noreferrer'>
                <Icon icon="ph:instagram-logo" style={ iconStyle }/>
              </Nav.Link> */}
            </Nav>
            {/* <Nav>
              <ThemeSwitch />
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </>;
}

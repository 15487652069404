
const styles = {
    textAlign: 'center'
};

export const mainBannerText = {
    home: {
        heading1: 
            <div>
                <h2 style={styles}>Welcome to Regal Tax Consultants</h2>
            </div>,
    },
    taxPartner: {
        heading1: 
            <div>
                <h2 style={styles}>I'll Teach You How To Grow Your Tax Business</h2>
            </div>,
        heading2: 
        <div>
            <h3 style={styles}>Your Six Figure Business Starts Here</h3>
            <h5 style={styles}>* Quality * Impact * Success *</h5>
        </div>
    },
    taxClient: {
        heading1: 
            <div>
                <h2 style={styles}>Our Goal Is To Earn Your Trust</h2>
            </div>,
    }
}
